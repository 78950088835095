define(['app', 'urlApi', 'urlReader'], function(app, urlApi, urlReader) {

  var __urlApiType = {};

  /**
   * Url Builder.
   * @namespace
   */
  var _urlBuilder = {

    /**
     * Module Constructor
     * @private
     */
    _init: function() {
      __urlApiType = _urlBuilder._apiType();

      _urlBuilder._changeDetection(__urlApiType);

      _urlBuilder._updateIdentifiers(__urlApiType.variableQuery, __urlApiType.categoryValueSeparator, __urlApiType.categorySeparator, __urlApiType.segmentSeparator);

      app.subscribe('url/update', function() {
        _urlBuilder._engine.updateSegment.apply(__urlApiType, arguments);
      });

      app.subscribe('url/updatePath', function(newUrl) {
        _urlBuilder._engine.updateUrl.call(__urlApiType, newUrl);
      });

      app.subscribe('url/updateIdentifiers', function() {
        _urlBuilder._updateIdentifiers.apply(this, arguments);
        _urlBuilder._checkURLEncoded();
      });

      app.subscribe('url/crossCompatibility', function(url, urlParameters) {
        urlParameters = urlReader.getCategories(urlParameters);
        urlParameters = urlReader.constructParameters(urlParameters, true);
        _urlBuilder._engine.updateUrl.call(__urlApiType, url + _urlBuilder._keySegments.variableQuery + urlParameters);
      });

      app.subscribe('url/init', function() {
        if (__urlApiType.hasOwnProperty('initLoad') && __urlApiType.initLoad(app.publish, 'url/crossCompatibility')) {
          app.publish('facets/updatePage');
        }
      });
    },

    /**
     *
     * @param variableQuery
     * @param categoryValueSeparator
     * @param categorySeparator
     * @param segmentSeparator
     * @private
     */
    _updateIdentifiers: function(variableQuery, categoryValueSeparator, categorySeparator, segmentSeparator) {
      _urlBuilder._keySegments = {
        variableQuery: variableQuery || _urlBuilder._keySegments.variableQuery,
        segmentSeparator: segmentSeparator || _urlBuilder._keySegments.segmentSeparator,
        categorySeparator: categorySeparator || _urlBuilder._keySegments.categorySeparator,
        categoryValueSeparator: categoryValueSeparator || _urlBuilder._keySegments.categoryValueSeparator
      };
      urlReader.decoupler = _urlBuilder._keySegments;
    },

    _keySegments: {
      variableQuery: '?',
      categoryValueSeparator: '=',
      categorySeparator: '&',
      segmentSeparator: ':'
    },

    /**
     * Build server side urls or front end urls
     * @returns {object}
     * @private
     */
    _apiType: function() {
      for (var key in urlApi) {
        if (urlApi.hasOwnProperty(key) && urlApi[key].apiCheck) {
          return urlApi[key];
        }
      }
    },

    /**
     * Dynamic url detection
     * @param {object} __urlApiType
     * @private
     */
    _changeDetection: function(__urlApiType) {
      __urlApiType.dynamicUpdate(app.publish, 'url/change');
    },

    /**
     * Check for any external encoding via third party system
     * @private
     */
    _checkURLEncoded: function() {
      var url = window.location.href;
      var unsecureKeys = ['|'];
      var secureKey = '';
      var urlSegment = '';

      for (var i = 0; i < unsecureKeys.length; i++) {
        var unsecureKey = unsecureKeys[i];
        urlSegment = encodeURIComponent(unsecureKey);

        if (url.indexOf(urlSegment) > -1) {
          for (var key in _urlBuilder._keySegments) {

            if (_urlBuilder._keySegments.hasOwnProperty(key)) {
              var segment = _urlBuilder._keySegments[key];

              if (segment === unsecureKey) {
                secureKey = segment;
                break;
              }
            }
          }
        }
        url = url.split(urlSegment).join(secureKey);
      }

      if (url !== window.location.href) {
        _urlBuilder._engine.updateUrl.call(__urlApiType, url);
      }
    },

    /**
     * Return the engine object
     */
    _engine: {
      /**
       * Update the URL directly
       * @param url
       */
      updateUrl: function(url) {
        this.apiUpdateUrl(url);
      },

      /**
       * Update an individual segment
       * @param action
       * @param segment
       * @param category
       */
      updateSegment: function(action, segment, category) {
        _urlBuilder._run({
          url: this.url(),
          variableQuery: this.variableQuery,
          categorySeparator: this.categorySeparator,
          action: action,
          category: category,
          segment: segment,
          updateSegment: this.updateFragment
        });
      }
    },

    /**
     * Run the url update
     * @param {object} config
     * @private
     */
    _run: function(config) {
      urlReader.decoupler.variableQuery = config.variableQuery = config.variableQuery || _urlBuilder._keySegments.variableQuery;
      urlReader.decoupler.segmentSeparator = config.segmentSeparator = config.segmentSeparator || _urlBuilder._keySegments.segmentSeparator;

      if (config.category) {
        _urlBuilder._categoryValues(config);
        return;
      }
      _urlBuilder._update(config);
    },

    /**
     * Get the category and category parameters
     * @param {object} config
     * @private
     */
    _categoryValues: function(config) {
      urlReader.decoupler.categorySeparator = config.categorySeparator = config.categorySeparator || _urlBuilder._keySegments.categorySeparator;
      urlReader.decoupler.categoryValueSeparator = config.categoryValueSeparator = config.categoryValueSeparator || _urlBuilder._keySegments.categoryValueSeparator;

      if (!config.url.split(config.variableQuery)[1]) {
        config.categorySeparator = '';
        config.segmentSeparator = '';
        _urlBuilder._update(config, {
          value: config.category,
          exists: false
        });
        return;
      }

      var getVariables = config.url.split(config.variableQuery)[1];

      if (getVariables) {
        var urlParams = getVariables.split(config.categorySeparator);
        var urlCategories = urlParams.map(function(obj) {
          return obj.split(config.categoryValueSeparator)[0];
        });

        for (var i = 0, urlCategoriesLength = urlCategories.length; i < urlCategoriesLength; i++) {
          var urlCategory = urlCategories[i];
          if (urlCategories[i] === config.category) {
            _urlBuilder._update(config, {
              value: urlCategory,
              exists: true
            }, urlParams[i]);
            return;
          }
        }
      }
      _urlBuilder._update(config, {
        value: config.category,
        exists: false
      }, getVariables);
    },

    /**
     * Modify segment
     * @param {object} config
     * @param {object} [category]
     * @param {string} [urlParams]
     * @private
     */
    _update: function(config, category, urlParams) {
      if (config.category) {
        _urlBuilder._segmentModifier(config, category, urlParams);
        return;
      }
      _urlBuilder._segmentModifier(config);
    },

    /**
     *
     * @param {object} config
     * @param {object} [category]
     * @param {string} [urlParams]
     * @private
     */
    _segmentModifier: function(config, category, urlParams) {
      var url = window.location.href;
      if (config.action === 'add') {
        url = _urlBuilder._segmentAdd(config, category, urlParams);
      }

      if (config.action === 'remove') {
        url = _urlBuilder._segmentRemove(config, category, urlParams);
      }
      config.updateSegment(url);
    },

    /**
     *
     * @param {object} config
     * @param {object} category
     * @param {string} urlParams
     * @returns {string}
     * @private
     */
    _segmentAdd: function(config, category, urlParams) {
      var url = config.url;
      if (category) {
        if (category.exists) {
          config.category = '';
          config.categorySeparator = '';
          config.categoryValueSeparator = '';
          config.variableQuery = '';

          var categoryValues = urlParams + config.segmentSeparator + config.segment;
          config.segmentSeparator = '';
          config.segment = '';
          url = url.split(urlParams);
          url = url[0] + categoryValues + url[1];
        }

        if (!category.exists) {
          config.segmentSeparator = '';
        }
      } else {
        if (url.indexOf(config.variableQuery) < 0) {
          url = url + config.variableQuery;
          config.segmentSeparator = '';
        }
        config.category = '';
        config.categorySeparator = '';
        config.categoryValueSeparator = '';
      }
      return url + config.variableQuery + config.categorySeparator + config.category + config.categoryValueSeparator + config.segmentSeparator + config.segment;
    },

    /**
     * Remove the segment from the url
     * @param {object} config
     * @param {object} category
     * @param {string} urlParams
     * @returns {string}
     * @private
     */
    _segmentRemove: function(config, category, urlParams) {
      var url = config.url;
      var urlStart = [];
      if (category && category.exists) {
        url = _urlBuilder._categorySegmentRemove(config, category, urlParams);
        config.variableQuery = '';
        urlStart[0] = '';
      } else {
        urlStart = url.split(config.variableQuery);
        url = urlStart[1].split(config.segmentSeparator);
        for (var i = 0, urlLength = url.length; i < urlLength; i++) {
          if (url[i] === config.segment) {
            url.splice(i, 1);
            break;
          }
        }
        if (url.length) {
          url = url.join(config.segmentSeparator);
        } else {
          config.variableQuery = '';
        }
      }
      return urlStart[0] + config.variableQuery + url;
    },

    /**
     * Remove the segment and the category if no segments
     * @param {object} config
     * @param {object} category
     * @param {string} urlParams
     * @returns {string}
     * @private
     */
    _categorySegmentRemove: function(config, category, urlParams) {
      var categoryValues = '';
      if (urlParams.indexOf(config.segmentSeparator + config.segment) > -1) {
        categoryValues = urlParams.split(config.segmentSeparator + config.segment);
      } else if (urlParams.indexOf(config.categoryValueSeparator + config.segment) > -1) {
        if (urlParams.indexOf(config.categoryValueSeparator + config.segment + config.segmentSeparator) > -1) {
          categoryValues = urlParams.split(config.segment + config.segmentSeparator);
        } else {
          categoryValues = '';
        }
      } else {
        categoryValues = urlParams.split(config.segment).join('');
        var categoryFragments = categoryValues.split(config.category + config.categoryValueSeparator);
        if (!categoryFragments[0] || !categoryFragments[1]) {
          categoryValues = '';
        }
      }

      if (config.categoryValueSeparator === config.segmentSeparator && categoryValues[0] === category.value) {
        categoryValues = '';
      }

      if (categoryValues === '' && config.url.indexOf(config.categorySeparator + urlParams) > -1) {
        urlParams = config.categorySeparator + urlParams;
      } else if (categoryValues === '' && config.url.indexOf(config.variableQuery + urlParams + config.categorySeparator) > -1) {
        urlParams = urlParams + config.categorySeparator;
      } else if (categoryValues === '' && config.url.indexOf(config.variableQuery + urlParams) > -1) {
        urlParams = config.variableQuery + urlParams;
      }
      var url = config.url.split(urlParams);

      if (typeof categoryValues !== 'string') {
        categoryValues = categoryValues.join('');
      }
      return url[0] + categoryValues + url[1];
    }
  };

  // Execute module constructor
  _urlBuilder._init();

  // Unit testing access
  return _urlBuilder;
});
